@import "../../styles/variables";

#not-found {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 30px 15px;
    text-align: center;

    .wrapper-text {
        padding-top: 0;
    }

    img {
        max-width: 250px;
        width: 100%;
        display: inline-block;
    }

    h2 {
        font-size: 140px;
    }

    p {
        font-weight: 500;
        font-size: 22px;
        line-height: 21px;
        color: #000000;
        margin-bottom: 45px;
    }

    a {
        display: block;
        margin: 0 auto;
        font-weight: normal;
        font-size: 18px;
        line-height: 17px;
        color: #ffffff;
        max-width: 394px;
        width: 100%;
        padding: 25px 15px;
        background: $blue;
        border-radius: 60px;
        transition: background .3s ease;

        &:hover {
            text-decoration: none;
            background: darken($blue, 5);
        }
    }

    @media screen and (max-width: 414px) {
        padding: 10px 15px;

        h2 {
            font-size: 100px;
        }

        p {
            font-size: 16px;
            line-height: 17px;
        }

        a {
            font-size: 14px;
            line-height: 13px;
            padding: 20px 15px;
        }
    }
}