@import "~bootstrap/scss/bootstrap";
@import "~animate.css";
@import "fonts/fonts";
@import "variables";

body {
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;

    #catskillet {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    &.menu-active {
        overflow: hidden!important;
    }
}

.wow { visibility: hidden; }

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}


.container-site {
    @extend .container;
    width: 100%;
    max-width: 1600px;
}

.wrapper-text {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 125px 0 0;
    text-align: center;

    .text {
        max-width: 810px;
        margin: auto;
    }

    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        color: $txt-dark-gray;
        margin-bottom: 20px;
    }

    p {
        font-style: normal;
        font-size: 30px;
        line-height: 36px;
        color: $txt-light-gray;
    }

    @media screen and (max-width: $large) {
        padding-top: 95px;

        h3 {
            font-size: 32px;
            line-height: 33px;
        }

        p {
            font-size: 24px;
            line-height: 28px;
        }
    }

    @media screen and (max-width: $hd) {
        padding-top: 75px;

        h3 {
            font-size: 28px;
            line-height: 29px;
        }

        p {
            font-size: 20px;
            line-height: 25px;
        }
    }

    @media screen and (max-width: $tablet-medium) {
        padding-top: 30px;

        .text {
            padding: 0 15px;
        }

        h3 {
            font-size: 24px;
            line-height: 24px;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
}