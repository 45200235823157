// Variables
$gray: #3F3F3F;
$dark-gray: #1F1F1F;
$light-gray: #E5E5E5;
$red: #FC473D;
$blue: #86C9FD;

$bg-gray: #ECECEC;
$bg-dark-gray: #3F3F3F;

$height-header: 80px;

$txt-dark-gray: #404040;
$txt-light-gray: #767676;

$full: 1920px;
$large: 1600px;
$retina: 1440px;
$hd: 1366px;
$tablet: 1024px;
$tablet-medium: 992px;
$tablet-small: 768px;
$mobile: 568px;
$iphone: 414px;
$iphone-small: 320px;